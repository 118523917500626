import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GraphModule } from '@x/common/graph';
import { DashboardCoreModule } from '@x/dashboard/core';
import { DashboardFormModule } from '@x/dashboard/form';
import { DashboardGridModule } from '@x/dashboard/grid';
import { GeoRegionDefinitionFormDialogComponent } from './components/georegion-form/georegion-definition-form-dialog.component';
import { GeoRegionFormDialogComponent } from './components/georegion-form/georegion-form-dialog.component';
import { GeoRegionDialogService } from './services/geo-region-dialog.service';

@NgModule({
  imports: [
    CommonModule,
    GraphModule,
    DashboardCoreModule,
    DashboardFormModule,
    DashboardGridModule,
  ],
  declarations: [GeoRegionFormDialogComponent, GeoRegionDefinitionFormDialogComponent],
  exports: [GeoRegionFormDialogComponent, GeoRegionDefinitionFormDialogComponent],
})
export class GeoRegionSharedModule {
  static forRoot(): ModuleWithProviders<GeoRegionSharedModule> {
    return {
      ngModule: GeoRegionSharedModule,
      providers: [GeoRegionDialogService],
    };
  }
}
