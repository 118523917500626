<h2 mat-dialog-title>{{ data.geoRegionId ? 'Edit ' : 'Add ' }}Region</h2>
<form
  mat-dialog-content
  [formGroup]="formGroup"
  id="georegion_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="disabled"
  xFormErrorBoundary
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field appearance="fill">
        <mat-label>Region Name</mat-label>
        <input matInput type="text" formControlName="name" required autocomplete="disabled" />
      </mat-form-field>

      <mat-form-field appearance="fill" xField>
        <mat-label>Parent Region</mat-label>
        <x-model-autocomplete
          placeholder="Search region..."
          [dataSource]="geoRegionAutocompleteDataSource"
          [displayWith]="geoRegionAutocompleteDataSource.displayFn"
          formControlName="parentId"
          [transformer]="geoRegionIdTransformer"
        ></x-model-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="channels$ | async as channels">
        <mat-label>Primary Channel</mat-label>
        <mat-select formControlName="primaryChannelId" placeholder="Select Primary Channel">
          <mat-option *ngFor="let channel of channels" [value]="channel.id">
            {{ channel.name }} ({{ channel.code }})
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="channels$ | async as channels">
        <mat-label>Channel(s)</mat-label>
        <mat-select formControlName="channelIds" placeholder="Select Channel(s)" multiple>
          <mat-option *ngFor="let channel of channels" [value]="channel.id">
            {{ channel.name }} ({{ channel.code }})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="submit" color="primary" form="georegion_form">Save</button>
</div>
