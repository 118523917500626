import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  GeoRegionService,
  IChannelDetailObject,
  IGeoRegionDetailObject,
} from '@x/ecommerce/domain-client';
import {
  ChannelContext,
  GeoRegionAutocompleteDataSource,
  GeoRegionIdTransformer,
} from '@x/ecommerce/domain-data';
import { CreateGeoRegionInput, UpdateGeoRegionInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

export interface GeoRegionFormDialogData {
  primaryChannelId?: number;
  geoRegionId?: number;
  parentId?: number;
}

export interface GeoRegionFormDialogResult {
  data?: IGeoRegionDetailObject;
}

@Component({
  selector: 'x-georegion-form-dialog',
  templateUrl: 'georegion-form-dialog.component.html',
  providers: [GeoRegionAutocompleteDataSource, GeoRegionIdTransformer],
})
export class GeoRegionFormDialogComponent implements OnInit {
  channelControl = new FormControl<number | null>(this.data.primaryChannelId ?? null, {
    validators: [Validators.required],
    nonNullable: true,
  });
  regionControl = new FormControl<number | null>(this.data.parentId ?? null);

  formGroup = new FormGroup({
    id: new FormControl<null | number>(null),
    name: new FormControl<null | string>(null, [Validators.required, Validators.maxLength(64)]),
    primaryChannelId: this.channelControl,
    channelIds: new FormControl<number[]>([]),
    parentId: this.regionControl,
  });

  channels$: Observable<IChannelDetailObject[]>;

  constructor(
    private channelContext: ChannelContext,
    public dialog: MatDialogRef<GeoRegionFormDialogComponent, GeoRegionFormDialogResult>,
    private geoRegionService: GeoRegionService,
    @Inject(MAT_DIALOG_DATA) public data: GeoRegionFormDialogData,
    public geoRegionAutocompleteDataSource: GeoRegionAutocompleteDataSource,
    public geoRegionIdTransformer: GeoRegionIdTransformer,
  ) {
    this.channels$ = channelContext.getAllChannels();
  }

  ngOnInit() {
    if (this.data.geoRegionId) {
      // update
      this.geoRegionService.fetchDetail(this.data.geoRegionId).subscribe((geoRegion) => {
        this.formGroup.setValue({
          id: geoRegion.id,
          name: geoRegion.name,
          primaryChannelId: geoRegion.primaryChannel.id,
          channelIds: geoRegion.channels.map((c) => c.id),
          parentId: geoRegion.parent?.id ?? null,
        });
      });
    }
  }

  submit() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) return;

    const form = this.formGroup.value;

    if (form.id) {
      const data: UpdateGeoRegionInput = {
        id: form.id,
        name: form.name,
        primaryChannelId: form.primaryChannelId,
        channelIds: form.channelIds,
        parentId: form.parentId,
      };
      this.geoRegionService.updateRegion(data).subscribe((geoRegion) => {
        this.dialog.close({
          data: geoRegion,
        });
      });
    } else {
      const data: CreateGeoRegionInput = {
        name: form.name ?? '',
        primaryChannelId: form.primaryChannelId as number,
        channelIds: form.channelIds ?? [],
        parentId: form.parentId,
      };

      this.geoRegionService.createRegion(data).subscribe((geoRegion) => {
        this.dialog.close({
          data: geoRegion,
        });
      });
    }
  }
}
