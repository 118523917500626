import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateGeoRegionDefinitionInput } from '@x/schemas/ecommerce';
import {
  GeoRegionDefinitionFormDialogComponent,
  GeoRegionDefinitionFormDialogData,
  GeoRegionDefinitionFormDialogResult,
} from '../components/georegion-form/georegion-definition-form-dialog.component';
import {
  GeoRegionFormDialogComponent,
  GeoRegionFormDialogData,
  GeoRegionFormDialogResult,
} from '../components/georegion-form/georegion-form-dialog.component';

@Injectable({ providedIn: 'root' })
export class GeoRegionDialogService {
  constructor(private dialogs: MatDialog) {}

  openGeoRegionCreateDialog(primaryChannelId?: number) {
    return this.dialogs.open<
      GeoRegionFormDialogComponent,
      GeoRegionFormDialogData,
      GeoRegionFormDialogResult
    >(GeoRegionFormDialogComponent, {
      data: { primaryChannelId },
    });
  }
  openGeoRegionEditDialog(geoRegionId?: number) {
    return this.dialogs.open<
      GeoRegionFormDialogComponent,
      GeoRegionFormDialogData,
      GeoRegionFormDialogResult
    >(GeoRegionFormDialogComponent, {
      data: { geoRegionId },
    });
  }

  openGeoRegionDefinitionCreateDialog(
    definition: Omit<CreateGeoRegionDefinitionInput, 'geoRegionId'>,
    selectedGeoRegionId?: number,
  ) {
    return this.dialogs.open<
      GeoRegionDefinitionFormDialogComponent,
      GeoRegionDefinitionFormDialogData,
      GeoRegionDefinitionFormDialogResult
    >(GeoRegionDefinitionFormDialogComponent, {
      data: { definition, selectedGeoRegionId },
    });
  }
}
