<h2 mat-dialog-title>GeoRegion Definition</h2>
<form
  mat-dialog-content
  [formGroup]="formGroup"
  id="georegion_definition_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="disabled"
  xFormErrorBoundary
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field *ngIf="geoRegions$ | async as regions">
        <mat-label>Select Region</mat-label>
        <mat-select
          xToolbarAction
          matTooltip="Select Region"
          placeholder="Filter Region"
          formControlName="regionId"
        >
          <mat-option *ngFor="let region of regions" [value]="region.id">
            {{ region.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="submit" color="primary" form="georegion_definition_form">Save</button>
</div>
