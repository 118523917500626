import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  GeoRegionDefinitionObject,
  GeoRegionObject,
  GeoRegionService,
} from '@x/ecommerce/domain-client';
import { CreateGeoRegionDefinitionInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

export interface GeoRegionDefinitionFormDialogData {
  definition: Omit<CreateGeoRegionDefinitionInput, 'geoRegionId'>;
  selectedGeoRegionId?: number;
}

export interface GeoRegionDefinitionFormDialogResult {
  data?: { definition?: GeoRegionDefinitionObject };
}

@Component({
  selector: 'x-georegion-definition-form-dialog',
  templateUrl: 'georegion-definition-form-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class GeoRegionDefinitionFormDialogComponent implements OnInit {
  geoRegions$: Observable<GeoRegionObject[]>;

  formGroup = new UntypedFormGroup({
    regionId: new UntypedFormControl(null, [Validators.required]),
  });

  constructor(
    public dialog: MatDialogRef<
      GeoRegionDefinitionFormDialogComponent,
      GeoRegionDefinitionFormDialogResult
    >,
    @Inject(MAT_DIALOG_DATA) private dialogData: GeoRegionDefinitionFormDialogData,
    private geoRegionService: GeoRegionService,
  ) {}

  ngOnInit() {
    this.geoRegions$ = this.geoRegionService.fetchDetails();
    this.formGroup.setValue({
      regionId: this.dialogData.selectedGeoRegionId ?? null,
    });
  }

  submit() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) return;

    const form = this.formGroup.value;

    const data: CreateGeoRegionDefinitionInput = {
      ...this.dialogData.definition,
      geoRegionId: form.regionId,
    };

    this.geoRegionService.createDefinition(data).subscribe((definition) => {
      this.dialog.close({
        data: { definition },
      });
    });
  }
}
